import { useQuery } from 'react-query';
import * as API from 'utils/API/API';

const Feed = API.feed();

export const useGetFeed = params => {
  return useQuery(['feed', params], () => {
    return Feed.list(params);
  });
};
