import React from 'react';
import { Grid, GridItem, Heading, Text, SkeletonText, Flex } from '@chakra-ui/react';
import Box from '../../components/Box/Box';
import { useGetFeed } from 'data-client/use-feed';
import eventsBg from '../../assets/photos/events-bg.jpg';
import XYChartFeed from 'analytics/xy-char-feed';
import { DateTime } from 'luxon';

const selectedDsps = ['Spotify'];

const FeedCard = ({ title, date = '', subtitle = '', type, tags, chart, route }) => {
  const dataSubset = chart?.data?.map((data, index) => ({ index, total: data })) || [];
  let startsAtDate = '';
  let time = '';

  if (date) {
    startsAtDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    time = DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
  }

  const handleClick = () => {
    const link = route?.web?.route;
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <GridItem
      onClick={handleClick}
      border="1px solid"
      borderColor="cream90"
      borderRadius=".5rem"
      p="1rem"
      bg="cream90"
      cursor="pointer">
      {type === 'chart' ? (
        <Box float="left" mr="20px" height="80px" width="120px" bg="white" borderRadius={12}>
          <XYChartFeed data={dataSubset} selectedDsps={selectedDsps} width={120} height={120} />
        </Box>
      ) : (
        <Box
          float="left"
          mr="20px"
          height="80px"
          width="120px"
          backgroundImage={`url(${eventsBg})`}
          backgroundSize="cover"
          backgroundPosition="bottom"
          borderRadius={12}
        />
      )}
      <Box variant="darkText">
        <Heading as="h3" size="sm" noOfLines={2} title={subtitle} fontWeight="700">
          {title}
        </Heading>
        {date && (
          <Text m="0.4rem" noOfLines={2} fontSize="0.9rem">
            {startsAtDate} / {time}
          </Text>
        )}
        <Text m="0.4rem" noOfLines={2} fontSize="0.9rem">
          {subtitle?.replace('\n', '')}
        </Text>
        <Flex>
          {tags.map(tag => (
            <Box key={tag.id} bgColor={`custom-tags.${tag.color}`} p="3px 10px" borderRadius="0.9rem">
              {tag.label}
            </Box>
          ))}
        </Flex>
      </Box>
    </GridItem>
  );
};

const SkeletonCard = () => {
  return (
    <GridItem rowSpan={2} colSpan={2}>
      <Box borderLeft="1px solid" pl="1rem" variant="darkText">
        <SkeletonText noOfLines={2} height="2.4rem" />
        <SkeletonText noOfLines={1} width="200px" />
      </Box>
    </GridItem>
  );
};

export default function FeedSection() {
  const feedQuery = useGetFeed();
  const list = feedQuery?.data || [];

  return feedQuery.isLoading ? (
    <Grid templateColumns="repeat(4, 1fr)" gap={6} width={'100vw'} mt="2rem" mb="5rem">
      <SkeletonCard></SkeletonCard>
      <SkeletonCard></SkeletonCard>
    </Grid>
  ) : (
    <>
      <Grid templateColumns={{ base: '100%', md: 'repeat(2, minmax(0, 1fr))' }} gap={6} mt="2rem">
        {list.map((feed, i) => {
          return <FeedCard {...feed} key={`feed-item-${i}`} />;
        })}
      </Grid>
    </>
  );
}
