import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react';
import asModal from 'features/Modal/as-modal';

function TerminatedAgreementModal() {
  return (
    <Box color="black100">
      <Heading size="md" mt={0} mb={'1.5rem'}>
        Glad to see you are ready to release new music!
      </Heading>
      <Text>
        Please visit our website <a href="venicemusic.co">venicemusic.co</a> for our new membership plans. If you have
        any additional questions, you may also reach out to{' '}
        <a href="mailto:arteam@venicemusic.co">arteam@venicemusic.co</a>.
      </Text>
    </Box>
  );
}

export default asModal(TerminatedAgreementModal);
