import * as React from 'react';
import { useTheme } from '@chakra-ui/react';
import { buildChartTheme, AreaSeries, XYChart as VisXYChart } from '@visx/xychart';
import { curveMonotoneX } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';

const accessors = {
  xAccessor: d => d.index,
  yAccessor: () => d => d.total,
};

const XYChartFeed = ({ data, width, height }) => {
  const chakraTheme = useTheme();

  const theme = buildChartTheme({
    colors: [chakraTheme.colors.dsp.Spotify],
  });

  return (
    <VisXYChart
      width={width}
      height={height}
      margin={{ top: 16, right: 0, left: 0, bottom: 48 }}
      theme={theme}
      xScale={{ type: 'band' }}
      yScale={{ type: 'linear' }}>
      <LinearGradient
        from={chakraTheme.colors.dsp.Spotify}
        fromOpacity="0.5"
        toOpacity="0"
        to={chakraTheme.colors.white}
        id="spotify-gradient"
      />
      <AreaSeries
        dataKey="Spotify"
        data={data}
        xAccessor={accessors.xAccessor}
        yAccessor={accessors.yAccessor('Spotify')}
        curve={curveMonotoneX}
        fill="url('#spotify-gradient')"
      />
    </VisXYChart>
  );
};

export default XYChartFeed;
