import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import GlobalStyles from './style/global-styles';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { Fonts } from './fonts';

// category > type > element > variant > state
//
// category : colors | fonts | ...
// type     : text | background | border | ...
// element  : page | button | table | ...
// variant  : primary | secondary | tertiary | ...
// state    : default | hover | active | ...

const colors = {
  black: '#000000',
  black0000: '#000000',
  black100: '#222028',
  black90: '#2D2A38',
  black80: '#413E4D',
  black70: '#565263',
  black60: '#686573',
  black50: '#9A98A0',
  black30: '#aaa8aa',
  black20: '#CCCBCF',
  black10: '#F0EFF1',
  black05: '#F8F8F8',
  cream50: '#FCFAF8',
  cream75: '#F7F6F6',
  cream80: '#C0B8AE',
  cream90: '#f5efea',
  cream100: '#EEE6DB',
  cream200: '#D2CFCA',
  green100: '#78CB0E',
  orange50: '#A65D28',
  orange100: '#DA7E3C',
  orange200: '#FF7C1E',
  red100: '#F5513A',
  red200: '#FF335F',
  gray30: '#F3F3F3',
  gray75: '#9a98a0',
  destructiveRed: '#C53232',
  pink: '#FF335F',
  pinkHalfOpacity: '#FF335F90',
  yellow100: '#F4F431',
  blue100: '#559AEB',
  blue10: '#F4F9FF',
  white: '#FFFFFF',
  transparent: 'transparent',
  dsp: {
    Spotify: '#4ADF7E',
    Amazon: '#39ADDC',
    Apple: '#FA7F8D',
    Pandora: '#7190EC',
    Spotify_Opacity_50: 'rgba(74, 223, 126, 0.5)',
  },
  'custom-tags': {
    'light-blue': 'rgba(170, 199, 255, 1)',
    'light-coral': 'rgba(255, 184, 170, 1)',
    'light-green': 'rgba(170, 255, 185, 1)',
    'light-lavender-pink': 'rgba(255, 170, 241, 1)',
    'light-lavender': 'rgba(227, 170, 255, 1)',
    'light-mint-green': 'rgba(170, 255, 227, 1)',
    'light-peach': 'rgba(255, 227, 170, 1)',
    'light-pink': 'rgba(255, 170, 199, 1)',
    'light-sky-blue': 'rgba(170, 241, 255, 1)',
    'light-yellow': 'rgba(241, 255, 170, 1)',
    'pastel-green': 'rgba(198, 255, 170, 1)',
    lavender: 'rgba(185, 170, 255, 1)',
  },
};

const theme = Object.assign(
  {},
  {
    breakpoint: (kind, size) => content => `@media (${kind}: ${theme.flexboxgrid.breakpoints[size]}) { ${content} }`,
    flexboxgrid: {
      gridSize: 12, // columns
      gutterWidth: 1, // rem (1rem == 16px)
      outerMargin: 2, // rem (2rem == 32px)
      mediaQuery: 'only screen',
      container: {
        sm: 46, // rem (46rem == 736px)
        md: 61, // rem (61rem == 976px)
        lg: 76, // rem (76rem == 1216px)
      },
      breakpoints: {
        xs: '30em', // em (30em == 480px)
        sm: '48em', // em (48em == 768px)
        md: '64em', // em (64em == 1024px)
        lg: '75em', // em (75em == 1200px)
      },
    },
    colors: {
      brand: {
        layer0: colors.black100,
        layer1: colors.black90,
        layer2: colors.black80,
        layer3: colors.black60,
        layer4: colors.black50,
        layer5: 'transparent',
        alertYellow: colors.yellow100,
        accent: colors.orange100,
        //accentHover: colors.orange200,
        emphasis: '#2B2933',
        buttonBackground: colors.black70,
        link: colors.pink,
        textWhite: colors.cream100,
        ghost: colors.cream200,
      },
      background: {
        button: {
          primary: {
            default: colors.orange100,
            // hover: colors.orange200,
            active: colors.orange50,
            disabled: colors.black50,
          },
          secondary: {
            default: colors.cream100,
            hover: colors.white,
            active: colors.cream200,
            disabled: colors.black50,
          },
          tertiary: {
            default: 'transparent',
            hover: colors.black90,
            active: 'transparent',
            disabled: 'transparent',
          },
          quaternary: {
            default: 'transparent',
            hover: colors.black90,
            active: 'transparent',
            disabled: 'transparent',
          },
          naked: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          dark: {
            default: colors.black100,
            hover: colors.black90,
            active: colors.black80,
            disabled: colors.black50,
          },
          short: {
            default: colors.cream100,
            hover: colors.white,
            active: colors.cream200,
            disabled: colors.black50,
          },
          block: {
            default: colors.black60,
            hover: colors.black50,
            active: colors.black80,
            disabled: colors.black50,
          },
          outline: {
            default: 'transparent',
            borderColor: colors.black100,
            hover: colors.black90,
          },
        },
        buttonToggle: {
          default: {
            default: colors.black80,
            hover: colors.black60,
            active: colors.black100,
            disabled: colors.black50,
            selected: colors.cream100,
          },
        },
        cardButton: {
          primary: {
            default: colors.black90,
            hover: colors.cream200,
            active: colors.cream100,
            disabled: colors.black80,
          },
        },
        catalogTile: {
          default: {
            default: 'transparent',
            hover: colors.black90,
            active: colors.black80,
          },
        },
        richButton: {
          primary: {
            default: colors.black90,
            hover: colors.cream200,
            active: colors.cream100,
            disabled: colors.black80,
          },
          secondary: {
            default: 'transparent',
            hover: colors.black90,
            active: 'transparent',
            disabled: 'transparent',
          },
        },
        tileButton: {
          primary: {
            default: colors.black90,
            hover: colors.cream200,
            active: colors.cream100,
            disabled: colors.black80,
          },
        },
        dropdown: {
          dark: {
            default: colors.black80,
            hover: colors.black60,
            disabled: colors.black80,
          },
          light: {
            default: colors.white,
            hover: colors.black05,
            disabled: colors.black50,
          },
        },
        dropdownList: {
          dark: {
            default: colors.black80,
          },
          light: {
            default: colors.white,
          },
        },
        dropdownItem: {
          dark: {
            hover: colors.black90,
          },
          light: {
            selected: colors.cream200,
            hover: colors.cream200,
          },
        },
        playerBarBg: {
          default: colors.black80,
          hover: colors.black80,
          selected: colors.black80,
        },
        playerBarFg: {
          default: colors.orange100,
          hover: colors.orange200,
          selected: colors.orange50,
        },
        progressBar: {
          uploading: colors.orange100,
          complete: colors.green100,
          error: colors.red100,
        },
        textInput: {
          dark: {
            default: colors.black80,
            hover: colors.black60,
            disabled: colors.black50,
            error: colors.black80,
            warning: colors.black80,
            phantom: 'transparent',
          },
          light: {
            default: colors.cream100,
            hover: colors.white,
            disabled: colors.black50,
            warning: colors.cream100,
            phantom: 'transparent',
          },
        },
        volumeSelector: {
          default: colors.cream200,
        },
        videoPlayer: {
          standard: {
            default: colors.cream200,
          },
        },
        videoPlayerButton: {
          standard: {
            default: colors.black90,
          },
        },
        multiCheckList: {
          standard: {
            default: colors.black60,
            hover: colors.black80,
            active: colors.black100,
          },
        },
        multiCheckListItem: {
          dark: {
            default: colors.black60,
            hover: colors.black80,
            active: colors.black100,
          },
          light: {
            default: colors.white,
            hover: colors.cream100,
            active: colors.black50,
          },
        },
        dropdownSearchHeader: {
          dark: {
            default: colors.black60,
          },
          light: {
            default: colors.cream200,
          },
        },
        dropdownSectionHeader: {
          dark: {
            default: colors.black60,
          },
          light: {
            default: colors.white,
          },
        },
        icon: {
          default: colors.cream100,
          hover: colors.orange200,
          active: colors.orange50,
          disabled: colors.black50,
        },
      },
      border: {
        button: {
          primary: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          secondary: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          tertiary: {
            default: colors.cream100,
            hover: colors.cream200,
            active: colors.cream100,
            disabled: colors.black70,
          },
          quaternary: {
            default: colors.black100,
            hover: colors.cream200,
            active: colors.cream100,
            disabled: colors.black70,
          },
          naked: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          dark: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          short: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          block: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
        },
        buttonToggle: {
          default: {
            default: colors.cream200,
            hover: colors.cream200,
            active: colors.cream200,
            disabled: colors.cream200,
            error: colors.red100,
            selected: colors.cream200,
          },
        },
        dropdown: {
          dark: {
            default: colors.black80,
            hover: colors.black80,
            disabled: colors.black50,
            error: colors.red100,
          },
          light: {
            default: colors.black20,
            hover: colors.black20,
            disabled: colors.black50,
            error: colors.red100,
          },
        },
        richButton: {
          primary: {
            default: 'transparent',
            hover: 'transparent',
            active: 'transparent',
            disabled: 'transparent',
          },
          secondary: {
            default: colors.cream200,
            hover: colors.cream200,
            active: colors.white,
            disabled: colors.black50,
          },
        },
        navItem: {
          dark: {
            default: colors.black100,
          },
          light: {
            default: colors.cream100,
          },
        },
        textInput: {
          dark: {
            default: colors.black80,
            hover: colors.black60,
            disabled: colors.black50,
            error: colors.red100,
            warning: colors.yellow100,
            phantom: colors.black50,
          },
          light: {
            default: colors.cream100,
            hover: colors.white,
            disabled: colors.black50,
            error: colors.red100,
            warning: colors.yellow100,
            phantom: colors.black50,
          },
        },
      },
      text: {
        button: {
          primary: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black70,
          },
          secondary: {
            default: colors.black100,
            hover: colors.black100,
            active: colors.black100,
            disabled: colors.black70,
          },
          tertiary: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black70,
          },
          quaternary: {
            default: colors.black100,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black70,
          },
          naked: {
            default: colors.cream200,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black70,
          },
          dark: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black70,
          },
          short: {
            default: colors.black100,
            hover: colors.black100,
            active: colors.black100,
            disabled: colors.black70,
          },
          block: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black60,
          },
        },
        buttonToggle: {
          default: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.cream100,
            disabled: colors.black60,
            selected: colors.black100,
          },
        },
        cardButton: {
          primary: {
            default: colors.cream200,
            hover: colors.black90,
            active: colors.black90,
            disabled: colors.black50,
          },
        },
        catalogTile: {
          default: {
            default: colors.cream100,
          },
          phantom: {
            default: colors.black50,
          },
          draft: {
            default: colors.yellow100,
          },
          submitted: {
            default: colors.blue100,
          },
          delivered: {
            default: colors.green100,
          },
          takendown: {
            default: colors.black50,
          },
        },
        richButton: {
          primary: {
            default: colors.cream200,
            hover: colors.black90,
            active: colors.black90,
            disabled: colors.black50,
          },
          secondary: {
            default: colors.cream200,
            hover: colors.cream200,
            active: colors.white,
            disabled: colors.black50,
          },
        },
        tileButton: {
          primary: {
            default: colors.cream200,
            hover: colors.black90,
            active: colors.black90,
            disabled: colors.black50,
          },
        },
        dropdown: {
          dark: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.black50,
            disabled: colors.black60,
          },
          light: {
            default: colors.black100,
            hover: colors.black100,
            active: colors.black90,
            disabled: colors.black70,
          },
        },
        dropdownList: {
          dark: {
            default: colors.cream100,
          },
          light: {
            default: colors.black90,
          },
        },
        dropdownItem: {
          dark: {
            selected: colors.black50,
            button: {
              default: colors.orange100,
              hover: colors.orange200,
            },
          },
          light: {
            selected: colors.black50,
            button: {
              default: colors.orange100,
              hover: colors.orange200,
            },
          },
        },
        fieldLabel: {
          dark: {
            default: colors.cream200,
          },
          light: {
            default: colors.black70,
          },
        },
        linkButton: {
          primary: {
            default: colors.orange100,
            hover: colors.orange200,
            active: colors.orange50,
            disabled: colors.black50,
          },
          secondary: {
            default: colors.cream100,
            hover: colors.orange100,
            active: colors.orange50,
            disabled: colors.black50,
          },
        },
        mediaPlayButton: {
          audio: {
            default: colors.cream100,
            hover: colors.white,
            active: colors.cream200,
            disabled: colors.black50,
            loading: colors.black80,
            error: colors.red100,
          },
          video: {
            default: colors.cream100,
            hover: colors.white,
            active: colors.cream200,
            disabled: colors.black50,
            loading: colors.black80,
            error: colors.red100,
          },
        },
        dropdownSearchHeader: {
          dark: {
            default: colors.cream200,
          },
          light: {
            default: colors.black100,
          },
        },
        dropdownSectionHeader: {
          dark: {
            default: colors.cream200,
          },
          light: {
            default: colors.black60,
          },
        },
        multiCheckList: {
          dark: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.black50,
            disabled: colors.black70,
          },
          light: {
            default: colors.black90,
            hover: colors.black90,
            active: colors.black90,
            disabled: colors.black70,
          },
        },
        multiCheckListItem: {
          light: {
            default: colors.black100,
            hover: colors.black100,
            active: colors.black100,
          },
          dark: {
            default: colors.cream100,
            hover: colors.cream100,
            active: colors.cream100,
          },
        },
        navItem: {
          dark: {
            default: colors.black100,
            hover: colors.orange200,
            active: colors.orange100,
          },
          light: {
            default: colors.cream100,
            hover: colors.orange200,
            active: colors.orange100,
          },
        },
        checkbox: {
          dark: {
            default: colors.cream100,
            disabled: colors.black50,
          },
          light: {
            default: colors.cream100,
            disabled: colors.black50,
          },
        },
        textInput: {
          dark: {
            default: colors.cream100,
            placeholder: colors.black60,
            hover: colors.cream100,
            disabled: colors.black70,
            error: colors.cream100,
            warning: colors.cream100,
          },
          light: {
            default: colors.black60,
            placeholder: colors.black80,
            hover: colors.black100,
            disabled: colors.black70,
            error: colors.white,
            warning: colors.white,
          },
        },
      },
      black: colors.black,
      grayLighter: '#C2C1C3',
      grayLight: '#3A3745',
      gray: colors.black70,
      prePopulated: colors.yellow100,
      disabled: '#E8E8E8',
      negative: colors.red100,
      negativeHover: '#b73725',
      positive: colors.green100,
      positiveHover: '#66AE0C',
      inactive: '#A0A4A8',
      inputBackground: colors.black80,
      inputBorder: '#A0A4A8',
      white: colors.white,
      error: colors.red100,
      searchBackground: colors.black50,
      ...colors,
    },
    fonts: {
      families: {
        text: 'Karla, Roboto, "Helvetica Neue", san-serif',
        display: 'Karla, Poppins, "Helvetica Neue", san-serif',
        numerals: 'Karla, Numerals, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        code: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        cursive: 'Dancing Script, cursive',
        headings: 'Neue Machina, Menlo, Monaco, Consolas, "Courier New", monospace',
      },
      weights: {
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        black: 900,
      },
      sizes: {
        base: '16px',
        xxxxxlg: '4rem', // 64px
        xxxxlg: '3rem', // 48px
        xxxlg: '2rem', // 32px
        xxlg: '1.5rem', // 24px
        xlg: '1.25rem', // 20px
        lg: '1rem', // 16px
        reg: '0.875rem', // 14px
        sm: '0.75rem', // 12px
        xsm: '0.6875rem', // 11px
        xxsm: '0.625rem', // 10px
      },
    },
    space: {
      px: '1px',
      0: '0',
      0.5: '0.125rem',
      1: '0.25rem', // 4px
      1.5: '0.375rem',
      2: '0.5rem', // 8px
      2.5: '0.625rem', // 10px
      3: '0.75rem', // 12px
      3.5: '0.875rem', // 14px
      4: '1rem', // 16px
      5: '1.25rem',
      6: '1.5rem', // 24px
      7: '1.75rem',
      8: '2rem', // 32px
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem', // 48px
      14: '3.5rem',
      16: '4rem', // 64px
      20: '5rem',
      24: '6rem',
      28: '7rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      96: '24rem',
    },
  },
  { client_name: '[Client-specific overrides object here]' }
);

const breakpoints = createBreakpoints({
  ...theme.flexboxgrid.breakpoints,
});

const chakraTheme = {
  ...theme,
  breakpoints,
  colors: {
    ...theme.colors,
    // override some colors with our own tokens
    gray: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: colors.black50,
      600: colors.black60,
      700: colors.black70,
      800: colors.black80,
      900: colors.black90,
    },
    orange: {
      50: '#FFFAF0',
      100: '#FEEBC8',
      200: '#FBD38D',
      300: '#F6AD55',
      400: colors.orange200,
      500: colors.orange100,
      600: colors.orange50,
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
    black: {
      5: colors.black05,
      20: colors.black20,
      60: colors.black60,
      100: colors.black100,
    },
    // new palette for our cream colors
    cream: {
      50: colors.cream50,
      100: colors.cream100,
      200: colors.cream200,
    },
    success: colors.green100,
    error: colors.red100,
    warning: colors.yellow100,
    info: colors.blue100,
  },
  components: {
    Header: {
      baseStyle: {
        backgroundColor: theme.colors.black10,
        boxSizing: 'border-box',
        left: 0,
        padding: `${theme.space['5']} ${theme.space['5']} ${theme.space['8']}`,
        position: 'sticky',
        right: 0,
        top: 0,
        zIndex: 200,
        display: 'flex',
        justifyContent: 'center',
      },
      variants: {},
    },
    Strong: {
      baseStyle: {
        color: theme.colors.black100,
        fontSize: theme.fonts.sizes.reg,
      },
      variants: {},
    },
    Select: {
      baseStyle: {
        field: {
          color: theme.colors.black100,
        },
        icon: {
          color: theme.colors.black100,
        },
      },
    },
    Heading: {
      baseStyle: {
        color: theme.colors.black100,
      },
      variants: {
        h1: {
          fontSize: theme.fonts.sizes.xxxxxlg,
          margin: 0,
          wordBreak: 'break-word',
        },
        h2: {
          color: theme.colors.black60,
        },
        h2Dark: {
          color: theme.colors.cream100,
        },
        h3: {
          fontFamily: theme.fonts.families.text,
          fontSize: theme.fonts.sizes.xlg,
          fontWeight: theme.fonts.weights.bold,
          marginBottom: '0.5rem',
          overflow: 'hidden',
          lineHeight: '1.6rem',
        },
        h4: {
          color: theme.colors.black60,
          fontSize: theme.fonts.sizes.xxlg,
        },
        hrRed: {
          display: 'flex',
          flexDirection: 'row',
          fontFamily: theme.fonts.families.headings,
          fontWeight: '700',
          fontSize: '2rem',
          lineHeight: '2rem',
          textTransform: 'uppercase',
          _after: {
            content: '""',
            flex: '1 1',
            borderBottom: '5px solid',
            borderColor: 'pink',
            margin: 'auto',
            marginLeft: '2rem',
          },
        },
        hr: {
          display: 'flex',
          flexDirection: 'row',
          fontFamily: theme.fonts.families.headings,
          fontWeight: '700',
          fontSize: '2rem',
          lineHeight: '2rem',
          textTransform: 'uppercase',
          _after: {
            content: '""',
            flex: '1 1',
            borderBottom: '1px solid',
            margin: 'auto',
            marginLeft: '1rem',
          },
        },
      },
    },
    Box: {
      baseStyle: {},
      variants: {
        newTag: {
          padding: '1px 3px 0',
          marginLeft: '0.5rem',
          borderRadius: '4px',
          border: '1px solid green',
          fontSize: '0.8em',
          fontWeight: theme.fonts.weights.bold,
          color: '#4aba5c',
          lineHeight: '1.0625rem',
        },
        buttonGroup: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        darkText: {
          color: theme.colors.black100,
        },
        xsText: {
          color: theme.fonts.sizes.xsm,
        },
        uppercaseLabel: {
          textTransform: 'uppercase',
          fontSize: '0.65rem',
          color: theme.colors.black60,
          fontWeight: theme.fonts.weights.bold,
        },
        tinyLogo: {
          alignItems: 'center',
          alignSelf: 'center',
          background: theme.colors.brand.layer4,
          borderRadius: '50%',
          color: theme.colors.brand.layer0,
          display: 'flex',
          fontSize: '0.5rem',
          fontWeight: theme.fonts.weights.bold,
          justifyContent: 'center',
          height: '0.75rem',
          marginRight: '0.5rem',
          width: '0.75rem',
        },
        messageUnderBubble: {
          color: 'gray.400',
          fontSize: '0.5rem',
          maxWidth: '90%',
        },
      },
    },
    Link: {
      variants: {
        darkLink: {
          color: theme.colors.black100,
          border: 'none',
          _last: { bortder: 'none' },
          _first: { border: 'none' },
          _hover: { textDecoration: 'none', color: theme.colors.black60 },
        },
        headerNavLink: {
          alignItems: 'center',
          borderRight: `1px solid ${theme.colors.black20}`,
          color: theme.colors.border.navItem.dark.default,
          fontFamily: theme.fonts.families.display,
          fontSize: theme.fonts.sizes.sm,
          fontWeight: theme.fonts.weights.medium,
          display: 'flex',
          height: '1.25rem',
          justifyContent: 'center',
          marginRight: '2rem',
          paddingRight: '2rem',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          _last: { border: 'none' },
          _first: { border: 'none' },
          _hover: { textDecoration: 'none', color: theme.colors.black60 },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          color: 'cream.100',
          borderColor: 'cream.100',
          background: 'transparent',
          _checked: {
            color: 'cream.100',
            borderColor: 'cream.100',
            background: 'transparent',
          },
        },
        label: {
          color: 'cream.100',
          fontWeight: 400,
          _checked: {
            fontWeight: 500,
          },
        },
      },
      variants: {
        smallLight: {
          control: {
            color: 'black100',
            borderColor: 'black100',
            background: 'transparent',
            _checked: {
              color: 'black100',
              borderColor: 'black100',
              background: 'black100',
            },
          },
          label: {
            color: 'black100',
            letterSpacing: 'normal',
            fontWeight: 400,
            fontSize: '.875rem',
            _checked: {
              fontWeight: 500,
            },
          },
        },
      },
    },
    Icon: {
      baseStyle: {
        color: theme.colors.black100,
        size: '1.5rem',
      },
    },
    Button: {
      baseStyle: {
        border: `1px solid ${colors.black100}`,
        color: colors.black100,
        backgroundColor: colors.transparent,
        _hover: {
          backgroundColor: colors.black20,
        },
      },

      variants: {
        pink: {
          color: colors.white,
          backgroundColor: colors.pink,
          fontSize: '0.75rem',
          _hover: {
            textDecoration: 'none',
            backgroundColor: colors.pink,
          },
        },
        white: {
          color: colors.black80,
          backgroundColor: colors.white,
          fontSize: '0.75rem',
          _hover: {
            textDecoration: 'none',
            backgroundColor: colors.white,
          },
        },
        small: {
          fontSize: '0.75rem',
        },
        dark: {
          backgroundColor: colors.black100,
          color: colors.white,
          _hover: {
            backgroundColor: colors.black80,
            color: colors.white,
          },
        },
        darkMode: {
          backgroundColor: colors.orange100,
          color: colors.cream100,
          borderColor: 'transparent',
          fontSize: '0.75rem',
          _hover: {
            backgroundColor: colors.orange50,
          },
        },
        smallCream: {
          borderColor: colors.cream100,
          fontSize: '0.75rem',
        },
        smallDark: {
          backgroundColor: colors.black100,
          color: colors.white,
          fontSize: '0.75rem',
          _hover: {
            backgroundColor: colors.black80,
            color: colors.white,
            _disabled: {
              backgroundColor: colors.black60,
              color: colors.white,
            },
          },
        },
        smallRed: {
          backgroundColor: colors.destructiveRed,
          color: colors.white,
          fontSize: '0.75rem',
          borderStyle: 'none',
          _hover: {
            backgroundColor: '#8A1F1F',
            color: colors.white,
          },
        },
        smallLighter: {
          color: colors.black60,
          borderColor: colors.black60,
          fontSize: '0.75rem',
          _hover: {
            backgroundColor: colors.black10,
          },
        },
        smallestDarkWhiteText: {
          color: colors.white,
          fontSize: '0.75rem',
          paddingX: '0.5rem',
          height: '2rem',
          borderColor: colors.white,
          backgroundColor: colors.black100,
          _hover: {
            backgroundColor: colors.black100,
            color: colors.white,
            _disabled: {
              backgroundColor: colors.black20,
              color: colors.black60,
              border: colors.black20,
            },
          },
          _disabled: {
            backgroundColor: colors.black20,
            color: colors.black60,
            border: colors.black20,
          },
        },
        transparent: {
          background: 'transparent',
          border: 0,
          color: theme.colors.black60,
          margin: 0,
          outline: 'none',
          padding: 0,
          fontWeight: 'bold',
          fontSize: '.75rem',
          _disabled: {
            color: theme.colors.black20,
          },
          _hover: {
            background: 'transparent',
            border: 0,
            margin: 0,
            padding: 0,
            color: theme.colors.black40,
          },
        },
        tanTransparent: {
          backgroundColor: colors.cream75,
          border: 0,
          color: colors.cream80,
          margin: 0,
          outline: 'none',
          padding: 0,
          fontWeight: 'bold',
          fontSize: '.75rem',
          _disabled: {
            color: theme.colors.black20,
          },
          _hover: {
            backgroundColor: 'transparent',
            border: 0,
            margin: 0,
            padding: 0,
            color: theme.colors.black40,
          },
        },

        invalid: {
          border: `1px solid ${colors.red100}`,
          color: colors.red100,
        },
      },
    },
    ButtonCard: {
      baseStyle: {
        marginRight: '1rem',
        position: 'relative',
        height: '14rem',
        maxWidth: '10rem',
        width: '100%',
        userSelect: 'none',
        textAlign: 'center',
        alignItems: 'center',
        background: theme.colors.background.cardButton.primary.default,
        borderRadius: '8px',
        color: theme.colors.text.cardButton.primary.default,
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: theme.fonts.weights.regular,
        padding: '0 1rem',
        overflow: 'hidden',
        transition: 'all 0.3s ease 0.1s;',

        _hover: {
          background: theme.colors.background.cardButton.primary.hover,
          color: theme.colors.text.cardButton.primary.hover,
        },

        _active: {
          background: theme.colors.background.cardButton.primary.active,
          color: theme.colors.text.cardButton.primary.active,
        },

        _lastChild: {
          marginRight: '0',
        },

        '&[aria-pressed="true"]': {
          background: theme.colors.background.cardButton.primary.hover,
          color: theme.colors.text.cardButton.primary.hover,
        },

        '&[aria-disabled="true"]': {
          background: theme.colors.background.cardButton.primary.disabled,
          color: theme.colors.text.cardButton.primary.disabled,
        },
      },
      variants: {
        dark: {},
        light: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: colors.black60,
          background: 'transparent',
          color: '#645F5F',

          _active: {
            background: 'transparent',
            color: colors.black100,
          },

          _hover: {
            background: 'transparent',
            color: colors.black100,
          },

          '&[aria-pressed="true"]': {
            background: colors.black20,
            color: colors.black100,
          },

          '&[aria-disabled="true"]': {
            background: 'transparent',
            color: colors.black40,
          },
        },
      },
      defaultProps: {
        variant: 'dark',
      },
    },

    Input: {
      baseStyle: {
        borderColor: theme.colors.black60,
        field: {
          color: theme.colors.black60,
        },
      },
      variants: {
        dark: {
          color: theme.colors.cream100,
          bg: theme.colors.black80,
          field: {
            color: theme.colors.black80,
          },
        },
        blackOutlined: {
          borderColor: theme.colors.black60,
          field: {
            color: theme.colors.black60,
          },
        },
        lightModeOutlined: {
          backgroundColor: theme.colors.black100,
          field: {
            color: theme.colors.black100,
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        icon: {
          color: 'black60',
        },
        control: {
          bg: 'transparent',
          backgroundColor: 'transparent',
          _checked: {
            bg: 'transparent',
            backgroundColor: 'transparent',
            borderColor: 'black60',
            _hover: {
              bg: 'transparent',
              backgroundColor: 'transparent',
              borderColor: 'black60',
            },
          },
          borderWidth: 2,
          borderColor: 'black60',
        },
        label: {
          color: 'black60',
          fontSize: 'reg',
        },
      },
      variants: {
        dark: {
          icon: {
            color: 'cream100',
          },
          control: {
            bg: 'transparent',
            backgroundColor: 'transparent',
            _checked: {
              bg: 'transparent',
              backgroundColor: 'transparent',
              borderColor: 'cream100',
              _hover: {
                bg: 'transparent',
                backgroundColor: 'transparent',
                borderColor: 'cream100',
              },
            },
            borderWidth: 2,
            borderColor: 'cream100',
          },
          label: {
            color: 'cream100',
            fontSize: '.75rem',
          },
        },
        light: {
          icon: {
            color: 'black100',
          },
          control: {
            bg: 'transparent',
            backgroundColor: 'transparent',
            _checked: {
              bg: 'transparent',
              backgroundColor: 'transparent',
              borderColor: 'black60',
              _hover: {
                bg: 'transparent',
                backgroundColor: 'transparent',
                borderColor: 'black100',
              },
            },
            borderWidth: 1.5,
            borderColor: 'black100',
          },
          label: {
            color: 'black100',
            fontSize: '.75rem',
          },
        },
      },
    },
    Drawer: {
      parts: ['dialog'],
      baseStyle: {
        dialog: {
          color: colors.black100,
          backgroundColor: colors.black05,
        },
      },
    },
    Menu: {
      parts: ['list', 'item'],
      baseStyle: {
        list: {
          bg: colors.white,
        },
        item: {
          color: colors.black100,
        },
      },
    },
    Modal: {
      parts: ['dialog', 'dialogContainer', 'body', 'footer', 'header'],
      baseStyle: {
        dialog: {
          bg: colors.white,
        },
        dialogContainer: {
          alignItems: 'center',
        },
        body: {
          paddingX: '10',
        },
        footer: {
          paddingX: '10',
          paddingTop: '0',
          paddingBottom: '8',
          gap: '2',
        },
        header: {
          color: colors.black100,
          fontWeight: 'bold',
          fontSize: 'xxlg',
          fontFamily: 'display',
          paddingX: '10',
          paddingTop: '16',
          paddingBottom: '0',
        },
      },
      defaultProps: {
        size: 'lg',
      },
      variants: {
        dark: {
          dialog: {
            bg: colors.black90,
          },
          body: {
            color: colors.black50,
            h4: {
              color: colors.cream100,
            },
          },
          footer: {
            button: {
              secondary: {
                bg: colors.transparent,
              },
              bg: colors.orange100,
              borderRadius: '0.25rem',
              color: colors.cream100,
              _hover: {
                bg: colors.orange200,
              },
            },
          },
          header: {
            color: colors.cream100,
          },
        },
      },
    },
    Popover: {
      parts: ['content'],
      baseStyle: {
        content: {
          bg: 'gray.700',
          backgroundColor: 'gray.700',
        },
      },
    },
    Table: {
      parts: ['thead', 'th', 'tr', 'td', 'tbody'],
      variants: {
        analytics: {
          tr: {
            borderBottom: `1px solid ${colors.cream200}`,
            counterIncrement: 'row-num',
          },
          thead: {
            borderBottom: `1px solid ${colors.cream200}`,
          },
          th: {
            paddingInlineStart: 0,
          },
          td: {
            paddingInlineStart: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          tbody: {
            counterReset: 'row-num',
          },
        },
        light: {
          th: {
            backgroundColor: theme.colors.black60,
            color: theme.colors.black20,
          },
        },
      },
      baseStyle: {
        thead: {
          bg: 'gray.800',
          color: colors.black60,
        },
        th: {
          color: colors.black60,
          fontWeight: 'medium',
          letterSpacing: '2px',
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          borderRightColor: 'gray.600',
          _last: {
            borderRightWidth: 0,
          },
        },
        tr: {
          borderBottom: '1px',
          color: colors.black100,
          _hover: {
            backgroundColor: colors.black10,
          },
        },
      },
    },
    Small: {
      baseStyle: {},
      variants: {
        metaDataLabel: {
          display: 'inline-block',
          fontWeight: theme.fonts.weights.medium,
          letterSpacing: '1px',
          textTransform: 'uppercase',
          color: theme.colors.black100,
        },
      },
    },
    LabeledInput: {
      parts: ['div', 'label', 'input'],
      baseStyle: {
        div: {
          padding: '0.25rem',
          marginY: '0.5rem',
        },
        label: {
          fontSize: '0.75rem',
          margin: '0 0 0.25rem',
          letterSpacing: 'normal',
        },
        input: {
          border: `1px solid ${colors.black80}`,
          color: colors.black80,
          padding: '0.5rem',
          width: '100%',
          borderRadius: '0.25rem',
          focusBorderColor: colors.black80,
          borderColor: colors.black80,
        },
      },
      variants: {},
    },
    LabeledSelect: {
      parts: ['div', 'label', 'select'],
      baseStyle: {
        div: {
          padding: '0.25rem',
          marginY: '0.5rem',
        },
        label: {
          fontSize: '0.75rem',
          margin: '0 0 0.25rem',
          letterSpacing: 'normal',
        },
        select: {
          border: `1px solid ${colors.black80}`,
          color: colors.black80,
          borderRadius: '0.25rem',
          focusBorderColor: colors.black80,
          borderColor: colors.black80,
        },
      },
      variants: {},
    },
    LabeledRadioToggle: {
      parts: ['div', 'label', 'radio', 'radioGroup', 'radioLabel'],
      baseStyle: {
        div: {
          padding: '0.25rem',
          marginY: '0.5rem',
        },
        label: {
          fontSize: '0.75rem',
          margin: '0 0 0.25rem',
          color: theme.colors.black90,
        },
        radio: {
          marginX: '0.25rem',
        },
        radioGroup: {
          display: 'flex',
        },
        radioLabel: {
          color: theme.colors.black90,
          marginRight: '1.5rem',
        },
      },
    },
    LabeledTextarea: {
      parts: ['div', 'label', 'textarea', 'limitText'],
      baseStyle: {
        div: {
          padding: '0.25rem',
          marginY: '0.5rem',
        },
        label: {
          fontSize: '0.75rem',
          margin: '0 0 0.25rem',
          color: theme.colors.black90,
        },
        textarea: {},
        limitText: {
          textAlign: 'right',
        },
      },
    },
    TierCard: {
      baseStyle: {
        background: 'white',
        padding: '1.5rem',
        borderRadius: '.5rem',
        border: '1px solid',
        borderColor: theme.colors.black60,
        color: theme.colors.black90,
        width: '21rem',
        _selected: {
          border: '2px solid',
          borderColor: 'pink',
        },
        _hover: {
          borderColor: 'pink',
        },
      },
    },
  },
  config: { initialColorMode: 'light' },
  styles: {
    global: {
      '.markdown-styles': {
        p: { lineHeight: '1.4rem', margin: '0 0 1rem', color: 'black80' },
        ol: {
          paddingInlineStart: '1.5rem',
          margin: '0 0 1rem',
          color: 'black80',
          li: { 'p:last-of-type': { marginBottom: 0 } },
        },
        ul: {
          paddingInlineStart: '1.5rem',
          margin: '0 0 1rem',
          color: 'black80',
          li: { 'p:last-of-type': { marginBottom: 0 } },
        },
        'h1, h2, h3, h4, h5, h6': { color: 'black80' },
      },
    },
  },
  // this is off by one from Chakra's default theme, but will keep our convention
  fonts: {
    ...theme.fonts,
    body: theme.fonts.families.text,
    heading: theme.fonts.families.display,
    mono: theme.fonts.families.code,
    ...theme.fonts.families,
  },
  fontSizes: {
    ...theme.fonts.sizes,
  },
  fontWeights: {
    ...theme.fonts.weights,
  },
};

const extendedTheme = extendTheme(chakraTheme);

const Theme = ({ children }) => (
  <ChakraProvider theme={extendedTheme}>
    <Fonts />
    {children}
    <GlobalStyles theme={extendedTheme} />
  </ChakraProvider>
);

export default Theme;

export { chakraTheme };
