import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl() + '/feed';

const Feed = () => {
  const list = () =>
    AuthAxios.get(`${DataApiUrl}`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  return {
    list,
  };
};

export default Feed;
